<template>
  <div style="background-color:#2d2b2f; height: 100%;">
    <div>
      <div>
        <div class="lc-flex-row">
        <div class="font-20-28 white m-l15 w25">*******</div>
        <!-- 屏幕宫格切换 -->
        <div class="lc-flex-row m-b10">
          <div :class="clickStyle == '1' ? 'white' : 'col-53'" class="pd-lr20 t-center" @click="screenClick('1')">
            <div class="iconfont icon-a-1X1" style="font-size: 25px;"></div>
            <div class="font-14">1x1</div>
          </div>
          <div :class="clickStyle == '2' ? 'white' : 'col-53'" class="pd-lr20  t-center" @click="screenClick('2')">
            <div class="iconfont icon-a-2X2" style="font-size: 25px;"></div>
            <div class="font-14">2x2</div>
          </div>
          <div :class="clickStyle == '3' ? 'white' : 'col-53'" class="pd-lr20 t-center" @click="screenClick('3')">
            <div class="iconfont icon-a-3X3" style="font-size: 25px;"></div>
            <div class="font-14">3x3</div>
          </div>
        </div>
        </div>
        <!-- 视频翻页 -->
        <div class="lc-flex-row splice">
          <div v-for="(value , index) in pagingList">
            <div class="border-top border-left border-right bor-sty-qgry5 pd-tb8 pd-lr10 radius_t10 m-t29 m-r15"
                 :class="paging == index ? 'white black-bg' : 'col-53'" v-if="pagingList.length <= '4'" @click="pagingClick(value + 1 , index)">
              第{{value + 1}}屏
            </div>
            <div class="border-top border-left border-right bor-sty-qgry5 pd-tb8 pd-lr12 radius_t10 m-t29 m-r15"
                 :class="paging == index ? 'white black-bg' : 'col-53'" v-else @click="pagingClick(value + 1 , index)">
              {{value + 1}}
            </div>
          </div>
          <div class="border-top border-left border-right bor-sty-qgry5 pd-tb8 pd-lr10 radius_t10 m-t29 m-r15 col-53"
               v-show="_indexList.length > 6 && (pagingval != _indexList.length - 1 && pagingval != _indexList.length)" >...</div>
        </div>
      </div>
      <div>

        <div class="w98 h100 m-t20 t-center video-grid" :class="clickStyle == '1' ? 'video-grid1x1' : clickStyle == '2' ? 'video-grid2x2' :'video-grid3x3'" style="background-color: #232125;">
          <!-- 视频 -->
          <div v-for="(value,index) in cameras" class="border bor-sty-qgry5"
               v-show="clickStyle == '1' ? (pagingval-1) == index : clickStyle == '2' ? index >= (pagingval-1) * 4 && index < pagingval * 4 : index >= (pagingval-1) * 9 && index < pagingval * 9">
            <div :id="'gateCamera_'+index" class="pd-t10"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import EZUIKit from "ezuikit-js";

import {projectDevices} from '@/api/device'

export default {
  name: 'gateMonitor',
  data() {
    return {
      clickStyle: '2',
      gateId:'',
      players:[],
      cameras:[],
      paging:'0',  //当前页数下标
      pagingval:'1',  //当前页数
      pagingList:[],  //显示的分页列表
      _index:[],  //所有的分页列表
      _indexList:[],  //宫格页码列表
      pagingIndex:[],
      _width:'',
      _height:'',
      camerasIds:[],
      token:'at.as4xo1x43rs7ygok4768qzp9amnv1nph-4g4e5zin1o-0avmtsq-pke0npzhd',
      projectId:0


    }
  },
  //vue的初始化方法，页面一初始化，就去拿参数
  created() {
    this.gateId = sessionStorage.getItem("gateId")
  },
  mounted() {
    // this.open(this.gateId);
    let projectId = this.$route.query.id
    this.projectId = projectId
    this.open()
  },
  methods: {
    /**
     * 打开窗口初始化
     */
    open() {
      this.clear();

      // this.$showload();
      let cameraInfos = []
      let  p = {projectId:this.projectId}
      projectDevices(p).then(res=>{
        if(res.code === 200){
          cameraInfos = res.data.list
          this.token = res.data.ezToken
        }
        this.pagingIndex = [];
        this._indexList = [];
        this._index = [];
        this._width = '';
        this._height = '';
        if(null == cameraInfos || cameraInfos.length <= 0) return;
        this.cameras =[]
        for(var i = 0; i < cameraInfos.length; i ++){
          this.cameras.push({
            deviceSerial: cameraInfos[i].playDeviceSeriNo,
            validateCode: cameraInfos[i].validateCode,
            channelNumber: cameraInfos[i].playChannel
          });
          this._index.push(i);
        }
        console.log("this.this.cameras==",this.cameras)
        console.log("this.this._index==",this._index)
        if(this.clickStyle == '1'){
          this._width = window.innerWidth - 200;
          this._height = window.innerHeight - 142;
          if(this._index.length > '4'){
            this.pagingList = this._index.slice(0 , 6);  //展示的分页列表
            this._indexList = this._index;
          }else{
            this.pagingList = this._index;
          }
        }else if(this.clickStyle == '2'){  // 四宫格
          this._width = (window.innerWidth - 200) / 2;
          this._height = (window.innerHeight - 142) / 2;
          for(var i = 0; i < this._index.length / 4; i ++){
            this.pagingIndex.push(i);
            this._indexList.push(i);
          }
          if(this.pagingIndex.length > '4'){
            this.pagingList = this.pagingIndex.slice(0 , 6);  //展示的分页列表
          }else{
            this.pagingList = this.pagingIndex;
          }
        }else {  // 九宫格
          this._width = (window.innerWidth - 300) / 3;
          this._height = (window.innerHeight - 142) / 3;
          for(var i = 0; i < this._index.length / 9; i ++){
            this.pagingIndex.push(i);
            this._indexList.push(i);
          }
          if(this.pagingIndex.length > '4'){
            this.pagingList = this.pagingIndex.slice(0 , 6);  //展示的分页列表
          }else{
            this.pagingList = this.pagingIndex;
          }
        }
        this.$nextTick(()=> {
          for (var i = 0; i < this._index.length; i++) {
            var _validateCode = '';
            if (null != cameraInfos[i].validateCode && "" != cameraInfos[i].validateCode) {
              _validateCode = cameraInfos[i].validateCode + "@";
            }
            var _deviceSerial = cameraInfos[i].playDeviceSeriNo;
            var _channelNumber = cameraInfos[i].playChannel;
            if (_channelNumber == undefined || _channelNumber==null){
              _channelNumber = 1
            }
            var list = new EZUIKit.EZUIKitPlayer({
              id: 'gateCamera_' + i, // 视频容器ID
              width: this._width,
              height: this._height,
              accessToken: this.token,

              url: 'ezopen://open.ys7.com/' + _deviceSerial + '/'+_channelNumber+'.hd.live',//表示高清
              // url: 'ezopen://open.ys7.com/' + _deviceSerial + '/1.live',//表示流畅模式

              audio: false,
              handleError: (err) => {
                if (err.type === "handleRunTimeInfoError" && err.data.nErrorCode === 5) {
                  // 加密设备密码错误
                }
              }
            })

            /*list.play().then(res=>{
              console.log("list.play().then(res)",res)
            });*/
            this.players.push(list);
            console.log("this.pagingIndex.length==",this.pagingIndex.length)
          }

          let index = 0;

          /**
           * 这里主要是为了防止几个设备同时请求，会超过萤石的并发控制数
           */
          function playDevice(){
            if (index < this.players.length) {
              let player = this.players[index];
              setTimeout(() => {
                player.play()
                index++;
                playDevice();
              }, 500);
            }
          }
          playDevice()
        })
      })

      //视频初始化

    },

    playVideo(){
      if(this.players.length>0){
        let i =0;

        function play(){
          if(i<this.players.length){
            let player = this.players[i]
            player.play()
            setTimeout(()=>{

            },500)
          }
        }

      }
    },

    clear() {
      if(null != this.players && this.players.length > 0){
        console.log("销毁播放器实例")
        for(var i = 0; i < this.players.length; i ++){
          if(null != this.players[i] ){
            this.players[i].stop();
            document.getElementById('gateCamera_' + i).innerHTML = "";
          }
        }
        this.players = [];
      }
      this.cameras = [];
    },

    // 屏幕宫格切换
    screenClick(num){
      this.clickStyle = num;
      this.paging = '0';
      this.pagingval = '1';
      this.open(this.gateId);
    },



    // 翻页
    pagingClick(val , index){
      // 页数
      // index 当前页数下标
      // this.pagingList.length 显示的分页列表数
      // this._index.length 所有的分页总数
      if (this._indexList.length > 6 && (index == '0' && val != 1)) {
        this.paging = index + 1;
        this.pagingList = this._index.slice(val - 2, val + 4);  //展示的分页列表
      } else if (this._indexList.length > 6 && (index == this.pagingList.length - 1 && this._index.length != val)) {
        this.paging = index - 1;
        this.pagingList = this._index.slice(val - 5, val + 1);
      }else{
        this.paging = index;
      }
      this.pagingval = val;
    },
  },
  computed: {},
  props: {},
  components: {
  },
  beforeDestroy() {
    this.clear();
  },
  activated() {
    let projectId = this.$route.query.id
    this.projectId = projectId
    this.open()
  },
  directives: {}
}
</script>

<style>
.video-grid{
  margin-left: auto;
  margin-right: auto;
}
.m-b10{
  margin-left: 10px;
}
.splice{
  display: flex;
  flex-direction: row;
  align-content: space-between;
  width: 500px;
}
.pd-lr20{
  margin-left: 50px;
}

.video-grid1x1{
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 一列 */
  grid-template-rows: repeat(1, 1fr); /* 一行 */
}

.video-grid2x2{
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 两列 */
  grid-template-rows: repeat(2, 1fr); /* 两行 */
}

.video-grid3x3{
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 三列 */
  grid-template-rows: repeat(3, 1fr); /* 三行 */
}
.gateCamera_0play-window{

}


</style>
